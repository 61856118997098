/* eslint-disable import/prefer-default-export */
import NotificationType from './notification-type';

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK'
};

export const DRIVER_MIN_AGE = 18;

export const GENDERS = [
  'MALE',
  'FEMALE',
  'UNKNOWN',
  'NOT_APPLICABLE'
];

export const PAYMENT_TYPE = [
  'CASH',
  'BANK_TRANSFER'
];

export const VEHICLE_MIN_PRODUCTION_YEAR = 1950;

export const COLOURS = [
  'YELLOW',
  'WHITE',
  'BLACK',
  'GRAY',
  'RED',
  'DARKBLUE',
  'LIGHTBLUE',
  'BROWN',
  'GREEN',
  'PINK',
  'ORANGE',
  'PURPLE',
  'BEIGE'
];

export const VEHICLE_CATEGORIES = [
  'STANDARD',
  'COMFORT',
  'DELIVERY',
  'SOBER_DRIVER',
  'WAV'
];

export const VEHICLE_OPTIONS = [
  'CHILD_SEAT',
  'PET_FRIENDLY'
];

export const VEHICLE_GEARBOXES = [
  'AUTOMATIC',
  'MANUAL'
];

export const AUTH = {
  JWT_LOCAL_STORAGE_KEY: 'authToken'
};

export const ADMIN_USER_GROUP_NAME = 'admin';

export { NotificationType };
